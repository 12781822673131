import React from 'react';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import H1 from '@rotaready/frecl/build/H1';
import Text from '@rotaready/frecl/build/Text';
import FlexContainer from '@rotaready/frecl/build/FlexContainer';
import Button from '@rotaready/frecl/build/Button';

import { normalizePost } from '../utilities/blog';

import Body from '../components/layout/Body';
import ResponsiveContainer from '../components/layout/ResponsiveContainer';
import SEO from '../components/SEO';
import PostCardContainer from '../components/blog/PostCardContainer';
import Hero from '../components/blog/Hero';
import ContentWrapper from '../components/layout/ContentWrapper';

const SubTitle = styled(Text)`
  font-size: 20px;
  line-height: 28px;
  margin-top: 10px;
`;

const Pagination = styled(FlexContainer)`
  justify-content: center;
`;

const PaginationButton = styled(Button)`
  margin: 0 10px;
  width: 100px;
`;

const BlogCategory = ({ data, pageContext }) => {
  const { previousPagePath, nextPagePath, category } = pageContext;

  const posts = data.allWpPost.edges.map(edge => normalizePost(edge.node));

  return (
    <Body>
      <SEO
        title={`${category.name} - Blog - Rotaready`}
        description={`Posts categorised in '${category.name}' in the Rotaready Blog`}
        url={`blog/category/${category.slug}`}
      />
      <ResponsiveContainer>
        <ContentWrapper>
          <Hero>
            <H1 uistyle="brand160" text={category.name} />
            <SubTitle uistyle="grey" text={category.description} />
          </Hero>

          <PostCardContainer posts={posts} />

          <Pagination>
            {previousPagePath
            && (
              <Link to={pageContext.previousPagePath}><PaginationButton uistyle="primary" ghost text="Previous" /></Link>
            )}
            {nextPagePath
            && (
              <Link to={pageContext.nextPagePath}><PaginationButton uistyle="primary" text="Next" /></Link>
            )}
          </Pagination>
        </ContentWrapper>
      </ResponsiveContainer>
    </Body>
  );
};

BlogCategory.propTypes = {
  data: PropTypes.shape({}).isRequired,
  pageContext: PropTypes.shape({}).isRequired,
};

export default BlogCategory;

export const query = graphql`
  query($skip: Int!, $limit: Int!, $categoryId: String) {
    allWpPost(
      sort: { fields: [date], order: DESC }
      skip: $skip
      limit: $limit
      filter: { categories: { nodes: { elemMatch: { id: { in: [$categoryId] } } } } }
    ) {
      edges {
        node {
          ...PostSummaryFragment
        }
      }
    }
  }
`;
